import axios from 'axios';
// import store from '@/store'
import message from '@/utils/message';
import router from '@/router'
import {
  closeWebSocket
} from "@/utils/socket"
import ssoAuth from '@/utils/util/ssoAuth';

const instance = axios.create({
  baseURL: "/api"
})

instance.interceptors.request.use(config => {
  return config
}, (err) => {
  return Promise.reject(err)
})


instance.interceptors.response.use((data) => {

  return data;
}, (err) => {
  return Promise.reject(err)
})

function request(method, url, params) {
  return new Promise((resolve, reject) => {
    let format = method.toLocaleLowerCase() == 'get' ? 'params' : 'data';
    instance({
      method: method,
      url: url,
      [format]: params,
    }).then(res => {
      res = res.data;
      let {
        resCode,
        data
      } = res;
      if (resCode == 0) {
        resolve(res)
        if (window.location.pathname == "/loginVerification") {
          window.location.href = "/index";
        }
      } else if (resCode == "001106") {
        closeWebSocket();
        if (window.location.pathname == "/loginVerification") {
          ssoAuth();
        }else if (window.location.pathname != "/login") {
          router.replace({
            path: "/login"
          })
        }

      } else {
        resolve(res)
        message.error(res.errMsg || "请求超时，请重试")

      }
    }).catch(res => {
      reject(res)
    })
  })
}

export function download(method, url, params, isDownload = true, filename) {
  return new Promise((resolve, reject) => {
    let format = method.toLocaleLowerCase() == 'get' ? 'params' : 'data';
    instance({
      method: method,
      url: url,
      [format]: params,
      responseType: 'blob'
    }).then(res => {
      if (res.headers['content-type'] == "application/json") {
        let reader = new FileReader(); // 创建读取文件对象
        reader.readAsText(res.data, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
        reader.addEventListener("loadend", function () { // 
          let res = JSON.parse(reader.result); // 返回的数据
          message.error(res.errMsg)
          // MessageBox({
          //     title: "错误提示",
          //     message: res.errMsg,
          //     dangerouslyUseHTMLString: true,
          //     type: "error",
          //   })
          //   .then(() => {})
          //   .catch(() => {});
          reject(res)
        });
      } else {
        let name = res.headers['content-disposition'] && res.headers['content-disposition'].split('filename=')[1] && res.headers['content-disposition'].split('filename=')[1].split('.')[0] || "";
        if (name) {
          name = decodeURI(name)
        }
        let type = res.headers['content-type'] || 'application/vnd.ms-excel';
        let blob = new Blob([res.data], {
          type: type
        });

        const urls = URL.createObjectURL(blob);
        let downloadName = filename || name || '运营数据维护';
        if (isDownload) {
          //设置连接
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = urls;
          link.download = downloadName; //filename || name || '运营数据维护';
          document.body.appendChild(link);
          //模拟点击事件
          link.click();
          document.body.removeChild(link) // 下载完成移除元素
          window.URL.revokeObjectURL(urls) // 释放掉blob对象
          resolve(res.data)
        } else {
          resolve({
            name: downloadName,
            blob: urls,
            data: res.data
          })
        }
      }
    }).catch(res => {
      reject(res)
    })
  })
}
export function post(url, params) {
  return new Promise((resolve, reject) => {
    request("post", url, params).then(res => {
      resolve(res)
    }).catch(res => {
      reject(res)
    })
  })
}
export function get(url, params) {
  return new Promise((resolve, reject) => {
    request("get", url, params).then(res => {
      resolve(res)
    }).catch(res => {
      reject(res)
    })
  })
}
export default request;