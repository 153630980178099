<template>
  <div class="home">
    <Header></Header>
    <div class="router-view">
      <router-view v-slot="{ Component }">
          <!-- :include="aliveList" -->
        <keep-alive :include="aliveList" >
          <component :is="Component"  :key="fullPath"></component>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
export default {
  name: "Home",
  setup(){
    const store = useStore();
    const route = useRoute()
    let fullPath = computed(()=>route.fullPath);
    let aliveList= computed(()=>{
      let paths = store.state.tagsRouter.map(i=>i.path);
      let componentNameList = paths.map(i=>{
        let pathArr = i.split('/')
        return pathArr[pathArr.length - 1]
      })
      componentNameList.push('Home')
      return componentNameList
    });
    return{
      fullPath,
      aliveList
    }
  },
  components: {
    Header,
  },
};
</script>
<style lang="scss" scoped>
.router-view {
  padding: 16px;
}
</style>
