import axios, {post,get} from '@/axios'


export const getMessageListApi = (params)=>{
  return post("/message/webNotice/getList",params)
}

export const readMessageApi = (params)=>{
  return post("/message/webNotice/batchRead",params)
}

export const allReadMessageApi = (params)=>{
  return get("/message/webNotice/allReadNotErp?systemCode=SUPP_COOPERATE")
}

