<template>
  <div style="  height: 100%;">
    <div class="all_read">
      <el-link type="danger" :underline="false" href="" target="_blank" @click="allReadMessage" v-if="messageList.length>0 && showReadAll"> 全部标记为已读</el-link>
    </div>
    <div v-infinite-scroll="load" :class="['infinite-list',{'unDataBox' : messageList.length == 0}]" :infinite-scroll-disabled="isFinished" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
      <div v-for="(item,index) in messageList" :key="index" class="infinite_item">
        <div class="msg_content">
          <p v-html="item.content"></p>
        </div>
        <div class="item_read">
          <el-link type="danger" :underline="false" href="" target="_blank" @click="readMessage(item,index)"> 标记为已读</el-link>
          <!-- <el-link type="danger" :underline="false" href="" target="_blank" style="margin-left:8px"> 查看</el-link> -->
        </div>
      </div>
      <div class="loading_box" v-if="!isFinished">
        <el-icon class="is-loading">
          <Loading />
        </el-icon>
        <span class="loading_text">加载中...</span>
      </div>
      <div v-else style="text-align: center;">
        {{messageList.length ==0 ?"暂无数据":"没有更多了"}}
      </div>
    </div>
  </div>
</template>

<script setup>
import emitter from "@/utils/mitt/index.js";
import {
  getMessageListApi,
  allReadMessageApi,
  readMessageApi,
} from "@/api/message";
import message from "@/utils/message";
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
  reactive,
  onActivated,
  nextTick,
} from "vue";
const props = defineProps({
  count: {
    type: [Number, String],
    default: 0,
  },
  showReadAll: {
    type: Boolean,
    default: true,
  },
  isChangeInit: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["update:count"]);
const isLoading = ref(false);
const isFinished = ref(false);
const messageParams = reactive({
  pageNum: 1,
  pageSize: 20,
  systemCode:"SUPP_COOPERATE",
});
const messageList = ref([]);

const load = () => {
  if (isLoading.value || isFinished.value) {
    return;
  }
  messageParams.pageNum++;
  getMessageList();
};

const initList = () => {
  messageParams.pageNum = 1;
  isFinished.value = false;
  isLoading.value = false;
  getMessageList();
};

const getMessageList = () => {
  getMessageListApi(messageParams)
    .then((res) => {
      if (res.resCode == 0) {
        let rows = res.data.rows || [];
        let total = res.data.total || 0;
        if (messageParams.pageNum == 1) {
          messageList.value = rows;
        } else {
          messageList.value = messageList.value.concat(rows);
        }
        // count.value = parseInt(total);
        emits("update:count", parseInt(total));
        if (messageList.value.length >= total) {
          isFinished.value = true;
        }
      }
      isLoading.value = false;
    })
    .catch((err) => {
      isLoading.value = false;
    });
};
const onMessageChange = () => {
  emitter.on("socketMessage", (res) => {
    if (res !== "0xA" && res.indexOf("{") == -1) {
      // count.value = res;
      emits("update:count", res);
      if (res == 0) {
        messageList.value = [];
      } else if (props.isChangeInit && messageList.value.length < 20) {
        initList();
      }
    }
  });
};

const readMessage = (item, index) => {
  readMessageApi({
    ids:[item.id],
    systemCode:"SUPP_COOPERATE",
  }).then((res) => {
    if (res.resCode == 0) {
      messageList.value.splice(index, 1);
      // emits("update:count", props.count - 1);
      message.success("标记为已读成功");
    }
  });
};

const allReadMessage = () => {
  allReadMessageApi({
    systemCode:"SUPP_COOPERATE",
  }).then((res) => {
    if (res.resCode == 0) {
      messageList.value = [];
      // count.value = 0;
      // emits("update:count", 0);
      message.success("全部标记为已读成功");
    }
  });
};

onActivated(() => {
  onMessageChange();
  initList();
});
defineExpose({
  initList,
});
</script>

<style lang="scss" scoped>
.unDataBox{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909399;
}
.all_read {
  position: absolute;
  right: 20px;
  top: 12px;
}
.item_read {
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.loading_box {
  justify-content: center;
  font-size: 16px;
  display: flex;
  align-content: center;
  .loading {
    width: 10px;
    height: 10px;
  }
  .loading_text {
    font-size: 12px;
    margin-left: 4px;
  }
}
.infinite-list {
  height: 100%;
  .infinite_item {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    .msg_content {
      line-height: 22px;
    }
  }
}
</style>