<template>
  <!-- trigger="hover" -->
  <div class="massagePopoverBox">
    <el-popover trigger="hover" placement="bottom" title="消息通知" :width="450" popper-class="massagePopover" @before-leave="beforeLeavePopover" @show="showPopover">
      <div class="MassageList" ref="massageListBoxRef">
        <MassageList ref="massageListRef" v-model:count="count"></MassageList>
      </div>
      <template #reference>
        <el-badge :value="count" class="item" :hidden="count<=0">
          <p class="iconfont message_icon">&#xe629;</p>
        </el-badge>
      </template>
    </el-popover>

  </div>
</template>

<script setup>
import emitter from "@/utils/mitt/index.js";
import MassageList from "@/components/MassageList";
import { onActivated, onMounted, ref } from "vue";
const count = ref(0);
const massageListRef = ref(null);
const massageListBoxRef = ref(null);
const showPopover = () => {
  massageListRef.value.initList();
};

const beforeLeavePopover = () => {
  massageListBoxRef.value.scrollTop = 0;
};
const onMessageChange = () => {
  emitter.on("socketMessage", (res) => {
    if (res !== "0xA" && res.indexOf("{") == -1) {
      count.value = res;
    }
  });
};
onMounted(() => {
  onMessageChange();
  massageListRef.value.initList();
});
</script>
<style lang="scss" >
.massagePopover {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  .el-popover__title {
    padding: 0 10px;
  }
}
</style>
<style lang="scss" scoped>
.massagePopoverBox {
  margin-right: 28px;
}

.MassageList{
  height: 400px;
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 10px;
  overflow-y: auto;
}
.message_icon {
  font-size: 17px;
  cursor: pointer;
}
:deep(.el-badge__content.is-fixed) {
  top: 13px;
  border: none;
  right: 5px;
  // right: 24px;
}

</style>