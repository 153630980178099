import {
  onActivated,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue"
import debounce from '@/utils/util/debounce'
export default function (offsetTop = 70) {
  const tableBoxRef = ref(null)
  const tableHeight = ref(500)

  const setHeight = ()=>{
    let bodyHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    tableHeight.value = bodyHeight - tableBoxRef.value.offsetTop - offsetTop;
  }
  const resize = (e)=>{
    debounce(()=>{
      setHeight();
    })
  }
  onActivated(()=>{
    setHeight();
  })
  onMounted(() => {
    setHeight();
    window.addEventListener('resize',resize)
  })
  onBeforeUnmount(()=>{
    window.removeEventListener('resize',resize)
  })
  return {
    setHeight,
    tableBoxRef,
    tableHeight,
  }
}