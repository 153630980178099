<template>
   <div class="wrap">
    <el-popover
    placement="bottom"
    :width="400"
    :visible="visible"
    popper-class="selectSuppPopover"
    @show="showPopover"
    @hide="hidePopover"
  >
  <div class="suppListWrap" v-click-outside="onClickOutside">
    <div class="input_box">
      <el-input v-model="params.suppName"    @keyup.enter.native="getListSupp" @input="onInput" placeholder="请输入供应商名称" clearable></el-input>
      <!-- <el-button class="btn" type="primary"  @click="getListSupp" >查询</el-button> -->
      
    </div>
    <div class="suppListBox" v-loading="loading">
      <div :class="['suppListItem',{selectSupp:selectSupp.id == item.id}]" v-for="(item,index) in suppList" :key="index" @click="relateSupp(item)">
        {{item.suppName}}
    </div>
    </div>
    <div v-if="suppList.length==0" class="unData">
      暂无数据
    </div>
  </div>
    <template #reference>
      <div  class="select_supp" @click="visible = true">
        {{selectSupp.suppName || "请选择供应商"}}
        <el-icon :class="['icon',{act:isHover}]"><ArrowDown /></el-icon>
      </div>
    </template>
  </el-popover>
   </div>
</template>

<script setup>
import { ClickOutside as vClickOutside } from "element-plus"
import message from "@/utils/message";
import debounce from '@/utils/util/debounce'
import {getListSuppApi,getRelatedSuppApi,relateSuppApi} from "@/api/base";
import { ref,onMounted, reactive } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
const router = useRouter();
const store = useStore();
const visible = ref(false)
const loading = ref(false)
const isHover = ref(false)
let suppList = ref([])
const selectSupp = ref({})
const params = reactive({
  suppName:""
})



const getListSupp = () => {
  getListSuppApi(params).then(res=>{
    if(res.resCode == 0){
      suppList.value = res.data
    }
    loading.value = false
  }).catch(()=>{
    loading.value = false
  })
}
const getRelatedSupp = () => {
  getRelatedSuppApi().then(res=>{
    if(res.resCode == 0 && res.data){
      selectSupp.value = res.data;
    }
  })
}
const relateSupp = (supp) => {
  loading.value = true
  relateSuppApi({suppId:supp.id}).then(res=>{
    if(res.resCode == 0){
      message.success("切换供应商成功");
      visible.value = false
      store.commit("clearTagsRouter");
      router.replace("/")
      getRelatedSupp()

      // window.location.reload()
    }
    loading.value = false

  }).catch(()=>{
    loading.value = false
  })
}
const onInput = () => {
  debounce(getListSupp,300)
}
const onClickOutside = () => {
  visible.value = false
}
const showPopover = () => {
  isHover.value = true
}
const hidePopover = () => {
  if(params.suppName != ""){
    params.suppName = ""
    getListSupp()
  }
  isHover.value = false
}
onMounted(()=>{
  getListSupp()
  getRelatedSupp()
})
</script>
<style lang="scss">
  .selectSuppPopover{
    padding:0 !important;
  }
</style>
<style lang="scss" scoped>
.wrap{
  margin-right: 28px;
}
.suppListWrap{
  text-align: center;
  .input_box{
    padding: 10px ;
    display: flex;
    align-content: center;
    .btn{
      margin-left: 10px;
    }
    
  }
  .suppListBox{
    max-height: 300px;
    overflow-y: auto;
    .suppListItem{
      padding: 10px ;
      line-height: 22px;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      border-bottom: 1px solid #f5f5f5;
      &:hover{
        background-color: #f5f5f5;
        color: #004adf;
      }
    }
    .selectSupp{
      background-color: #f5f5f5;
      color: #004adf;
      font-weight: bold;
    }
    
  }
  .unData{
    padding: 30px 0 ;
    color: #909399;
    font-size: 12px;
  }
}
.select_supp{
  color:#ffffff;
  cursor: pointer;
  display: flex;
  align-items:center;
  // font-weight: bold;
  .icon{
    margin-left:6px;
    transition: all 0.1s;
  }
  .act{
    transform: rotate(180deg);
  }
}
</style>