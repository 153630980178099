function ssoAuth() {

  if(location.search.includes("ticket")) {
    return;
  }
  let mapping = {
    "test.supp.idelamu.com": "https://test.sso.idelamu.com",
    "prepub.supp.idelamu.com": "https://prepub.sso.idelamu.com",
    "supp.idelamu.com": "https://sso.idelamu.com",
  };

  let redirect = encodeURIComponent( location.href);
  let redirect2 = encodeURIComponent(
    window.location.origin + "/loginVerification"
  );
  let host =
    mapping[window.location.host] ||
    mapping["test.supp.idelamu.com"];
  window.location.href = `${host}/login?redirect=${redirect}&redirect2=${redirect2}`;
}
export default ssoAuth;