import axios,{post,get} from '@/axios'


export const login = (params)=>{
  return post("/supp/login",params)
}

export const getUserInfo = (params)=>{
  return get("/basedata/user/getUserInfo?systemCode=SUPP_COOPERATE",params)
}

export const logout = (params)=>{
  return post("/logout",params)
}

export const updatePassword = (params)=>{
  return axios("PUT","/basedata/user/updatePassword",params)
}

export const fsLogin = (params)=>{
  return post("/fslogin",params)
}
 
export const adminLoginApi = (params)=>{
  return post("/login",params)
}

 
export const getRoutersApi = (params)=>{
  return post("/basedata/menu/getRouters?systemCode=SUPP_COOPERATE",params)
}

export const loginByTicket = params => {
  return post("/loginByTicket",params)

};
