import axios,{post,download} from '@/axios'


export const getListDeliveryOrder = (params)=>{
  return post("/suppcooperate/sh/list",params)
}

export const printTemplate = (params)=>{
  return download("post","/purchase/sh/printTemplate",params,false)
}

