
import axios,{post,get} from '@/axios'


export const getDict = (params)=>{
  return post("/basedata/dict/listDict",params)
}


export const getListSuppApi = (params)=>{
  return get("/suppcooperate/supplier/listSupp",params)
}


export const getRelatedSuppApi = (params)=>{
  return get("/suppcooperate/cght/getSupp",params)
}

export const relateSuppApi = (params)=>{
  return get("/suppcooperate/cght/relate",params)
}

